// Variables
$primary-color: #FF5733;
$secondary-color: #333;
$background-light: #f7f7f7;
$text-color: #333;
$header-bg-color: #333;
$footer-bg-color: #333;
$cta-bg-color: #FF5733;

@font-face {
  font-family: 'Oswald-Bold';
  src: url('../assets/fonts/Oswald-Bold.woff2') format('woff2'),
       url('../assets/fonts/Oswald-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Global styles
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: $background-light;
  color: $text-color;
}
html {
  scroll-behavior: smooth;
}
h1, .h1, h2 {
  color: $primary-color;
  margin-bottom: 1rem;
  font-family: 'Oswald-Bold', sans-serif;
}
h2 {font-size: 2rem;}

p, ul {
  margin:10px auto;
  font-size: 1rem;
}
ul {max-width: 1024px;}
a {color:#FF5733;}
.btn {
  padding: 0.8rem 2rem;
  font-size: 1.7rem;
  background-color: $cta-bg-color;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  border-radius:10px;
  text-decoration: none;

  &:hover {
    background-color: darken($cta-bg-color, 10%);
  }
}
// Header
.header {
  @include flex-center;
  padding: 1rem 5rem;
  background-color: $header-bg-color;
  color: white;
  justify-content:space-between;

  .logo .h1 {
    font-size: 2.3rem;
    font-weight: bold;
    .h1{ margin: 0px; }  
    p {font-size: .9rem; margin-top: 0px;}
  }
  .contact {
   text-align:right;
   span {color:white;}
  }
}

// Hero section
.hero {
  @include flex-center;
  flex-direction: column;
  height: 60vh;
  background-color: $background-light;
  text-align: center;
  background-image: url(../assets/photo-1512248607101-e26c16d28749.avif);
  background-position: center;


  h1 {font-size: 3.5rem;-webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;background-color: rgba(0, 0, 0, 0.7); padding: 8px 30px;}
  p {font-size: 1.2rem; background-color: rgba(0, 0, 0, 0.9); padding: 20px; color:white; max-width:55%;}

  .btn {
    padding: 0.8rem 2rem;
    font-size: 1.7rem;
    background-color: $cta-bg-color;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    border-radius:10px;
    text-decoration: none;

    &:hover {
      background-color: darken($cta-bg-color, 10%);
    }
  }
}

// Benefits section
.benefits {
  padding: 2rem;
  text-align: center;
  background-color: white;

  ul {
    list-style-type: none;
    padding: 0;
    margin:0px auto;
    width: 100%;

    li {
      margin-bottom: 1rem;
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      p {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}

// Testimonials section
.testimonials {
  padding: 2rem;
  background-color: #f0f0f0;
  text-align: center;

  .testimonial-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .testimonial {
    max-width: 300px;
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
.guitar-lessons-container, .parkland-guitar-tutor {
  padding: 2rem;
  background-color: #f0f0f0;

  max-width: 1128px;
  margin: 0 auto;
  .btn {margin-top: 30px;}
}
// Lead form
.form-section {
  padding: 2rem;
  text-align: center;
  background-color: $background-light;
  width:50%;
  margin:0px auto;
p,.btn {margin: 30px;}
 
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    

    input {
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      max-width: 300px;
    }

    button {
      padding: 0.8rem 2rem;
      font-size: 1rem;
      background-color: $cta-bg-color;
      color: white;
      border: none;
      cursor: pointer;

      &:hover {
        background-color: darken($cta-bg-color, 10%);
      }
    }
  }
}
.keyword-links {
  max-width: 850px;
  text-align: center;
  padding-bottom:25px;
}
// Footer
.footer {
  padding: 1rem;
  background-color: $footer-bg-color;
  color: white;
  text-align: center;
}



@media (max-width: 992px) {
  .header {padding: 1rem 2rem; text-align: center; h2 {font-size: 1.3rem;} .contact {text-align: center; padding-bottom: 10px;}}
  .header, .benefits ul li, .testimonials .testimonial-container {display: block;}
  .logo {font-size: 1.5rem !important;}
  .hero h1 {font-size: 1.8rem;}
  .hero p {font-size:15px}
  .form-section {padding: unset; width: 100%;}
  .benefits img {width: 100%; height: auto;}
  .testimonials .testimonial {margin: 20px auto;}
}



